import { Dispatch, SetStateAction, useState } from 'react'

type UseShowAllProps<T> = {
  list: T
  initialCount?: number
}

export const useShowAll = <T extends Array<unknown>>({
  list,
  initialCount = 3,
}: UseShowAllProps<T>): {
  isHidden: boolean
  setIsHidden: Dispatch<SetStateAction<boolean>>
  list: T
} => {
  const [isHidden, setIsHidden] = useState(true)

  return {
    isHidden,
    setIsHidden,
    list: isHidden && list ? (list.slice(0, initialCount) as T) : list,
  }
}
