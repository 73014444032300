import { isNotEmpty, declensionsOfNum } from '@gmini/utils'
import { useStore, useStoreMap } from 'effector-react'
import React, { useEffect, useState } from 'react'

import * as ismApi from '@gmini/ism-api-sdk'

import { buildShortUserLabel, buildUserLabel } from '@gmini/helpers'

import { MessageEditor, UserBadge } from '@gmini/components'

import { UserInfo } from '@gmini/common'

import { Comment } from '../../molecules'

import { IssueOfficialReply } from '../IssueOfficialReply'

import { changeStatus, issuePending$ } from '../../issue.store'

import { projectUserList$ } from '../../user.store'

import {
  fetchIssueCommentList,
  createIssueComment,
  removeIssueComment,
  issueCommentList$,
  issueCommentsPending$,
  createIssueCommentPending$,
  fetchIssueCommentListPending$,
} from './model'

import {
  Container,
  CommentList,
  EditorWrapper,
  MoreButton,
} from './Comments.styled'

type CommentsProps = {
  issue: ismApi.GTechIssue.IssuePopulated
  userInfo: UserInfo
  disabled?: boolean
  onUpdateIssueHandler?: (
    prevIssue: ismApi.GTechIssue.IssuePopulated,
    currentIssue: ismApi.GTechIssue.IssuePopulated,
  ) => void
}

const limit = 10

export const Comments = ({
  issue,
  userInfo,
  disabled,
  onUpdateIssueHandler,
}: CommentsProps) => {
  const issueCommentsPending = useStore(issueCommentsPending$)
  const createIssueCommentPending = useStore(createIssueCommentPending$)
  const fetchIssueCommentListPending = useStore(fetchIssueCommentListPending$)
  const issuePending = useStore(issuePending$)
  const users = useStore(projectUserList$)
  const [offset, setOffset] = useState(0)

  const { issueCommentList, total } = useStoreMap({
    store: issueCommentList$,
    keys: [issue.id],
    fn: (
      {
        byId$: byId,
        idsByIssueId$: idsByIssueId,
        totalByIssueId$: totalByIssueId,
      },
      [key],
    ) => {
      const idsList = idsByIssueId[key]

      if (idsList) {
        return {
          issueCommentList: idsList
            .map(id => byId[id])
            .filter(isNotEmpty)
            .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)),
          total: totalByIssueId[key],
        }
      }

      return { templateList: [], total: 0 }
    },
  })

  useEffect(() => {
    fetchIssueCommentList.submit({
      issueId: issue.id,
      issueVersion: issue.version,
      limit,
      offset,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issue.id, offset])

  const showMoreButton = issueCommentList && total > issueCommentList.length

  const countHiddenComments = total - (issueCommentList?.length || 0)
  const countMoreButton =
    countHiddenComments > limit ? limit : countHiddenComments

  const onAcceptOfficialReply = async () => {
    const nextIssue = await changeStatus({
      issueId: issue.id,
      issueVersion: issue.version,
      status: ismApi.IssueStatus.Enum.CLOSED,
    })
    onUpdateIssueHandler?.(issue, nextIssue)
  }
  const onRejectOfficialReply = async () => {
    const nextIssue = await changeStatus({
      issueId: issue.id,
      issueVersion: issue.version,
      status: ismApi.IssueStatus.Enum.IN_PROCESS,
      issueAnswerStatus: ismApi.IssueAnswer.StatusEnum.DECLINED,
    })

    onUpdateIssueHandler?.(issue, nextIssue)
  }
  const onDeleteOfficialReply = async () => {
    const nextIssue = await changeStatus({
      issueId: issue.id,
      issueVersion: issue.version,
      status: ismApi.IssueStatus.Enum.IN_PROCESS,
      issueAnswerStatus: ismApi.IssueAnswer.StatusEnum.DELETED,
    })

    onUpdateIssueHandler?.(issue, nextIssue)
  }

  return (
    <Container>
      {!disabled && (
        <EditorWrapper>
          <UserBadge>
            {buildShortUserLabel(
              users.find(user => user.id === userInfo.id) || null,
            )}
          </UserBadge>
          <MessageEditor
            onSubmit={async value => {
              await createIssueComment({
                issueId: issue.id,
                text: value,
                issueVersion: issue.version,
              })

              onUpdateIssueHandler?.(issue, issue)
            }}
            loading={createIssueCommentPending}
            placeholder='Заполните комментарий'
          />
        </EditorWrapper>
      )}

      <CommentList>
        {issueCommentList?.map(issueComment => {
          const { text, id, authorId, createdAt, deleted, key } = issueComment

          if (ismApi.IssueComment.isIssueComment(issueComment)) {
            return (
              <Comment
                key={key}
                text={text}
                authorShortLabel={buildShortUserLabel(
                  users.find(({ id }) => id === authorId) || null,
                )}
                authorLabel={buildUserLabel(
                  users.find(({ id }) => id === authorId) || null,
                )}
                createdAt={createdAt}
                onDelete={
                  userInfo.id === authorId
                    ? () => {
                        removeIssueComment({
                          issueId: issue.id,
                          commentId: id,
                          issueVersion: issue.version,
                        })
                        onUpdateIssueHandler?.(issue, issue)
                      }
                    : null
                }
                disabled={issueCommentsPending}
                deleted={deleted}
              />
            )
          }
          if (ismApi.IssueComment.isChangeAnswer(issueComment)) {
            const isLastAnswer = issue.answer ? id === issue.answer.id : false

            const isAllowDelete =
              issue.assignees.some(
                ({ assigneeId }) => assigneeId === userInfo.id,
              ) && //TODO Костыль, придумать как эту логику утащить на бэк
              issue.allowedActions?.changeStatus?.[
                ismApi.IssueStatus.Enum.IN_PROCESS
              ] &&
              issue.status === ismApi.IssueStatus.Enum.ON_REVIEW

            const isAllowAccept =
              issue.allowedActions?.changeStatus?.[
                ismApi.IssueStatus.Enum.CLOSED
              ]

            const isAllowReject =
              issue.allowedActions?.changeStatus?.[
                ismApi.IssueStatus.Enum.CLOSED
              ]

            return (
              <IssueOfficialReply
                officialReply={{
                  ...issueComment,
                  description: issueComment.text,
                  id,
                }}
                onDelete={
                  isLastAnswer && isAllowDelete ? onDeleteOfficialReply : null
                }
                onAccept={
                  isLastAnswer && isAllowAccept ? onAcceptOfficialReply : null
                }
                onReject={
                  isLastAnswer && isAllowReject ? onRejectOfficialReply : null
                }
                disabled={issuePending}
              />
            )
          }
        })}
      </CommentList>
      {showMoreButton && (
        <MoreButton
          disabled={fetchIssueCommentListPending}
          onClick={() => setOffset(prevValue => prevValue + limit)}
        >
          Посмотреть еще {countMoreButton}{' '}
          {declensionsOfNum(countMoreButton, [
            'предыдущий',
            'предыдущих',
            'предыдущих',
          ])}{' '}
          {declensionsOfNum(countMoreButton, [
            'комментарий',
            'комментария',
            'комментариев',
          ])}
        </MoreButton>
      )}
    </Container>
  )
}
