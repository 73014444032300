import { createProjectListService, getAttributesValueIds } from '@gmini/helpers'
import * as api from '@gmini/ism-api-sdk'
import * as smApi from '@gmini/sm-api-sdk'

import { createIssueTemplateService } from '../../createIssueTemplateListService'
import { attributesService } from '../../attribute.store'

export const { fetchProjectList, fetchProjectListPending$, projectList$ } =
  createProjectListService({
    fetchProjectListApi: smApi.Project.fetchList,
  })

export const { resetTemplateList, templateList$, fetchIssueTemplateList } =
  createIssueTemplateService({
    fetchIssueTemplateListApi: api.GTechIssueTemplate.fetchList,
  })

fetchIssueTemplateList.doneData.watch(({ list }) => {
  const valueIds = list.flatMap(({ attributes }) =>
    getAttributesValueIds(attributes),
  )
  if (!valueIds.length) {
    return
  }

  attributesService.fetchAttributeValues({
    valueIds,
  })
})
