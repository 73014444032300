import { useContextMenu } from '@gmini/common'
import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'
import {
  ColoredSelect,
  pencil,
  TextFieldMultilineWithPreview,
} from '@gmini/components'
import * as api from '@gmini/ism-api-sdk'

import {
  ArrowTabs,
  Button,
  Chain,
  ConfirmActionPopover,
  IconButton,
  ScrollBar,
  Tabs,
  Tooltip,
} from '@gmini/ui-kit'
import { useMediaQuery, useTheme } from '@mui/material'
import { useStore } from 'effector-react'
import React, { memo, useCallback, useEffect, useState } from 'react'

import { PreparedIssueStatus } from '@gmini/helpers'

import { gStationDocumentManagementUrl } from '../../../config'
import { buildIssueLink } from '../../../helpers'
import {
  changeStatus,
  fetchListLinkedIssueToFile,
  fetchMostRecentGTechIssue,
  updateGTechIssue,
} from '../../issue.store'
import { issueStatusList$ } from '../../issueStatus.store'
import { editIssuePending$ } from '../../organisms/EditIssuePopup/editIssuePending'

import { AdditionalContent } from './AdditionalContent'

import {
  AdditionalContentWrapper,
  BaseContentWrapper,
  CheckMark,
  Container,
  CopyPathIssueButton,
  LeftSideContent,
  LinearProgress,
  RightSideContent,
  ShortInfoBar,
  Subtitle,
  SwitchIssues,
} from './EditIssue.styled'

import { MainContent } from './MainContent'
import { MainTabIndex } from './tabIndex'
import { EditIssueProps } from './types'
import { IssueSubscriptionsMenu } from './IssueSubscriptionsMenu'

const copiedDelay = 5000

const nameFieldStyles = {
  fontSize: '24px',
  lineHeight: '28px',
  letterSpacing: '0.1px',
}

export const EditIssue = memo(
  ({
    userInfo,
    issue,
    selectedProject,
    projectUrn,
    origin = '',
    wrapperStyle,
    baseContentWrapperStyle,
    additionalContentWrapperStyle,
    onCopyIssueLink,
    onUpdateIssueHandler,
    renderFileViewer,
    renderFileValidationErrorsPopup,
    renderIssueOfficialReplyForm,
    onNextIssueHandler,
    onPrevIssueHandler,
    nextDisabled,
    prevDisabled,
  }: EditIssueProps) => {
    const issuePending = useStore(editIssuePending$)

    const statusList = useStore(issueStatusList$)

    const theme = useTheme()
    const isSmallResolution = useMediaQuery(theme.breakpoints.down(401))

    const [copied, setCopied] = useState(false)
    const [mainTabIndex, setMainTabIndex] = useState(MainTabIndex.main)

    const [anchorElPopover, setAnchorElPopover] = useState<Element | null>(null)

    useEffect(() => {
      if (issue.id && selectedProject) {
        ;(async () => {
          try {
            await fetchMostRecentGTechIssue({ id: issue.id })

            if (
              gStationDocumentManagementUrl &&
              selectedProject?.sourceType === 'GStation'
            ) {
              fetchListLinkedIssueToFile({ issues: [issue.id] })
            }
          } catch (error) {
            console.error(error)
          }
        })()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issue.id, selectedProject])

    useEffect(() => {
      if (copied) {
        const timer = setTimeout(() => setCopied(false), copiedDelay)

        return () => clearTimeout(timer)
      }
    }, [copied])

    const { ContextMenu } = useContextMenu<{
      event: React.MouseEvent
    }>([
      {
        title: 'Удалить',
        onClick: props => {
          setAnchorElPopover(props.event.target as Element)
        },
        icon: Icon.DELETE,
      },
    ])

    const onSubmitOfficialReplyForm = async ({
      description,
    }: {
      description: string
    }) => {
      const nextIssue = await changeStatus({
        issueId: issue.id,
        issueVersion: issue.version,
        status: api.IssueStatus.Enum.ON_REVIEW,
        description,
        authorId: userInfo.id,
      })

      onUpdateIssueHandler?.(issue, nextIssue)
    }

    const onUpdateIssue = useCallback(
      async (updateParams: Partial<api.GTechIssue.UpdateParams>) => {
        const nextIssue = await updateGTechIssue({
          id: issue.id,
          version: issue.version,
          ...updateParams,
        })

        onUpdateIssueHandler?.(issue, nextIssue)

        return nextIssue
      },
      [issue, onUpdateIssueHandler],
    )

    const onChangeStatus = useCallback(
      async (item: PreparedIssueStatus) => {
        const nextStatus = item.status

        if (nextStatus === api.IssueStatus.Enum.ON_REVIEW) {
          renderIssueOfficialReplyForm?.({
            open: true,
            onClose: () => renderIssueOfficialReplyForm({ open: false }),
            onSubmit: onSubmitOfficialReplyForm,
          })
          return
        }

        const isFromOnReviewToInProcess =
          nextStatus === api.IssueStatus.Enum.IN_PROCESS &&
          issue.status === api.IssueStatus.Enum.ON_REVIEW

        const changeStatusParams:
          | api.GTechIssue.ChangeStatusParams
          | api.GTechIssue.ChangeStatusToOnReviewParams
          | api.GTechIssue.ChangeStatusToInProcessParams = {
          issueId: issue.id,
          issueVersion: issue.version,
          status: nextStatus,
        }

        const nextIssue = isFromOnReviewToInProcess
          ? await changeStatus({
              ...changeStatusParams,
              status: nextStatus,
              issueAnswerStatus:
                userInfo.id === issue.author //TODO: перенести проверку на бэк
                  ? api.IssueAnswer.StatusEnum.DECLINED
                  : api.IssueAnswer.StatusEnum.DELETED,
            })
          : await changeStatus(changeStatusParams)

        onUpdateIssueHandler?.(issue, nextIssue)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [issue, onUpdateIssueHandler],
    )

    const onCopyLinkForIssue = () => {
      const linkIssue = buildIssueLink(origin, projectUrn, issue.id)
      onCopyIssueLink?.(linkIssue)
      setCopied(true)
    }

    const handleClosePopover = () => {
      setAnchorElPopover(null)
    }

    const allowStatusList = statusList.filter(item => {
      const { allowedActions } = issue

      return allowedActions && allowedActions.changeStatus[item.status]
    })

    const isIssueClosed = issue.status === api.IssueStatus.Enum.CLOSED

    return (
      <>
        {issuePending && <LinearProgress />}
        <Container id='edit-issue-child-id' styles={wrapperStyle}>
          <BaseContentWrapper styles={baseContentWrapperStyle}>
            {onPrevIssueHandler && onNextIssueHandler && (
              <SwitchIssues small={isSmallResolution}>
                <IconButton
                  onClick={() => onPrevIssueHandler?.(issue)}
                  disabled={prevDisabled}
                >
                  <ArrowTabs transform='rotate(180)' />
                  {!isSmallResolution && <span>Предыдущее замечание</span>}
                </IconButton>
                <IconButton
                  onClick={() => onNextIssueHandler?.(issue)}
                  disabled={nextDisabled}
                >
                  {!isSmallResolution && <span>Следующее замечание</span>}
                  <ArrowTabs />
                </IconButton>
              </SwitchIssues>
            )}

            <TextFieldMultilineWithPreview
              value={issue.name || ''}
              onSubmit={(value: string) => onUpdateIssue({ name: value })}
              disabled={issuePending || isIssueClosed}
              styleText={{
                fontWeight: 500,
                ...nameFieldStyles,
              }}
              maxLength={256}
              styleTextArea={nameFieldStyles}
              editIcon={pencil}
              editable={!!issue.allowedActions?.changeableFields.name}
            />
            <ScrollBar>
              <LeftSideContent>
                <ShortInfoBar>
                  <Subtitle>
                    Замечание <b>#{issue.id}</b>
                  </Subtitle>
                  <Tooltip
                    title={
                      copied ? 'Скопировано' : 'Скопировать адрес замечания'
                    }
                  >
                    <CopyPathIssueButton
                      data-test-id='copyLink'
                      onClick={onCopyLinkForIssue}
                    >
                      {copied ? (
                        <CheckMark color='#42AB85' />
                      ) : (
                        <Chain color='#353B60' opacity={0.6} />
                      )}
                    </CopyPathIssueButton>
                  </Tooltip>
                  <ConfirmActionPopover
                    title='Удалить файлы'
                    message={
                      <>
                        Вы уверены, что хотите навсегда удалить все файлы? Все
                        ссылки на ресурсы будут удалены. Это действие не может
                        быть отменено.
                      </>
                    }
                    anchorEl={anchorElPopover}
                    handleClose={handleClosePopover}
                    actions={
                      <>
                        <Button
                          size='regular'
                          color='secondary'
                          onClick={handleClosePopover}
                        >
                          Отменить
                        </Button>
                        <Button
                          color='warning'
                          onClick={() => {
                            handleClosePopover()
                          }}
                          size='regular'
                        >
                          Удалить
                        </Button>
                      </>
                    }
                  />
                  <ContextMenu />

                  <ColoredSelect
                    list={allowStatusList}
                    selectedItem={statusList.find(
                      item => item.status === issue.status,
                    )}
                    onChange={onChangeStatus}
                    disabled={issuePending}
                  />
                  <IssueSubscriptionsMenu
                    disabled={issuePending}
                    issue={issue}
                    onUpdateIssueHandler={onUpdateIssueHandler}
                    projectUrn={selectedProject.urn}
                  />
                </ShortInfoBar>
                {isSmallResolution && (
                  <Tabs
                    onChangeTab={setMainTabIndex}
                    activeTabIndex={mainTabIndex}
                    headerStyles={{
                      fontSize: '14px',
                      height: '40px',
                      gap: 19,
                      marginTop: 'auto',
                      marginBottom: '-1px',
                      borderBottom: 'none',
                    }}
                    tabs={[
                      {
                        title: 'Основное',
                        content: null,
                      },
                      {
                        title: 'Дополнительное',
                        content: null,
                      },
                    ]}
                  />
                )}
                {mainTabIndex === MainTabIndex.additional &&
                isSmallResolution ? (
                  <AdditionalContent
                    issue={issue}
                    selectedProject={selectedProject}
                    onUpdateIssueHandler={onUpdateIssueHandler}
                  />
                ) : (
                  <MainContent
                    userInfo={userInfo}
                    issue={issue}
                    selectedProject={selectedProject}
                    onUpdateIssueHandler={onUpdateIssueHandler}
                    renderFileViewer={renderFileViewer}
                    renderFileValidationErrorsPopup={
                      renderFileValidationErrorsPopup
                    }
                  />
                )}
              </LeftSideContent>
            </ScrollBar>
          </BaseContentWrapper>
          {!isSmallResolution && (
            <AdditionalContentWrapper styles={additionalContentWrapperStyle}>
              <ScrollBar>
                <RightSideContent>
                  {!isSmallResolution && (
                    <AdditionalContent
                      issue={issue}
                      selectedProject={selectedProject}
                      onUpdateIssueHandler={onUpdateIssueHandler}
                    />
                  )}
                </RightSideContent>
              </ScrollBar>
            </AdditionalContentWrapper>
          )}
        </Container>
      </>
    )
  },
)

EditIssue.displayName = 'EditIssue'
