import { Tabs, Tooltip } from '@gmini/ui-kit'
import { useStore } from 'effector-react'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'

import {
  ErrorMessage,
  fileValidationRules,
  isImage,
  maxCountErrorMessage,
  useFilesValidation,
  useShowAll,
} from '@gmini/helpers'

import {
  Gallery,
  ShowAll,
  smallPencil,
  TextFieldMultilineWithPreview,
} from '@gmini/components'

import * as api from '@gmini/ism-api-sdk'

import { Comments } from '../../organisms/Comments'
import { EditIssuePopupHistoryChanges } from '../../organisms/EditIssuePopupHistoryChanges'
import { IssueOfficialReply } from '../../organisms/IssueOfficialReply'

import {
  changeStatus,
  fetchIssueHistory,
  updateGTechIssue,
} from '../../issue.store'

import {
  deleteFile,
  downloadFile,
  fileLinkById$,
  fileList$,
  uploadFiles,
} from '../../file.store'

import { AttachFile } from '../../atoms'

import { editIssuePending$ } from '../../organisms/EditIssuePopup/editIssuePending'

import {
  ActivityWrapper,
  AttachmentCount,
  AttachmentTitlePanel,
  FieldName,
  ShowAllWrapper,
} from './EditIssue.styled'

import { TabIndex } from './tabIndex'
import { MainContentProps } from './types'

const copiedDelay = 5000

const initialCountFiles = 3

const descriptionFieldStyles = {
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.15px',
}

export const MainContent = ({
  userInfo,
  issue,
  onUpdateIssueHandler,
  renderFileViewer,
  renderFileValidationErrorsPopup,
}: MainContentProps) => {
  const issueAnswer = issue?.answer || null
  const fileList = useStore(fileList$)
  const issuePending = useStore(editIssuePending$)
  const fileLinkById = useStore(fileLinkById$)

  const [copied, setCopied] = useState(false)
  const [tabIndex, setTabIndex] = useState(TabIndex.comment)

  const [viewerImageCurrentIdx, setViewerImageCurrentIdx] = useState(0)

  const imgList = fileList?.filter(isImage)
  const imgLinkList = imgList?.map(({ id }) => fileLinkById[id]).filter(Boolean)

  const { validateFiles, clearFileErrors } = useFilesValidation({
    rules: fileValidationRules,
    onError: (errors: ErrorMessage[]) =>
      renderFileValidationErrorsPopup?.({
        open: true,
        errors,
        onClose: () =>
          renderFileValidationErrorsPopup?.({
            open: false,
          }),
      }),
  })

  useEffect(() => {
    fetchIssueHistory({
      issueId: issue.id,
      issueVersion: issue.version,
    })
  }, [issue.version, issue.id])

  useEffect(() => {
    if (issue.id && issue.version && tabIndex === TabIndex.activity) {
      fetchIssueHistory({
        issueId: issue.id,
        issueVersion: issue.version,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex])

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), copiedDelay)

      return () => clearTimeout(timer)
    }
  }, [copied])

  const {
    isHidden,
    list: slicedFileList,
    setIsHidden,
  } = useShowAll({
    list: fileList || [],
    initialCount: initialCountFiles,
  })

  const onUpdateIssue = useCallback(
    async (updateParams: Partial<api.GTechIssue.UpdateParams>) => {
      const nextIssue = await updateGTechIssue({
        id: issue.id,
        version: issue.version,
        ...updateParams,
      })

      onUpdateIssueHandler?.(issue, nextIssue)

      return issue
    },
    [issue, onUpdateIssueHandler],
  )

  const onOpenViewer = (file: api.GTechIssue.File) => {
    if (!isImage(file)) {
      return
    }
    const imgIdx = imgList?.indexOf(file) || 0
    setViewerImageCurrentIdx(imgIdx)
    renderFileViewer?.({
      linkList: imgLinkList!,
      open: true,
      setOpen: () =>
        renderFileViewer?.({
          open: false,
        }),
      startIndex: viewerImageCurrentIdx,
    })
  }

  const getImageLink = (id: number) =>
    imgList?.some(img => img.id === id) ? fileLinkById[id] : null

  const onUploadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    onUpdateIssueHandler?.(issue, issue)

    const files = event.target?.files
    if (!files) {
      return
    }

    clearFileErrors()

    const allowFiles = validateFiles(Object.values(files), fileList?.length)

    if (allowFiles.length) {
      await uploadFiles({ issue, files: allowFiles })
    }
  }

  const isMaxCountFiles = Number(fileList?.length) >= 20

  const onDeleteFile = (file: api.GTechIssue.File) => {
    deleteFile({
      fileId: file.id,
      issueId: issue.id,
      issueVersion: issue.version,
    })

    onUpdateIssueHandler?.(issue, issue)
  }

  const onDownloadFile = (file: api.GTechIssue.File) => {
    downloadFile({ fileId: file.id })
  }

  const onAcceptOfficialReply = async () => {
    const nextIssue = await changeStatus({
      issueId: issue.id,
      issueVersion: issue.version,
      status: api.IssueStatus.Enum.CLOSED,
    })

    onUpdateIssueHandler?.(issue, nextIssue)
  }
  const onRejectOfficialReply = async () => {
    const nextIssue = await changeStatus({
      issueId: issue.id,
      issueVersion: issue.version,
      status: api.IssueStatus.Enum.IN_PROCESS,
      issueAnswerStatus: api.IssueAnswer.StatusEnum.DECLINED,
    })

    onUpdateIssueHandler?.(issue, nextIssue)
  }
  const onDeleteOfficialReply = async () => {
    const nextIssue = await changeStatus({
      issueId: issue.id,
      issueVersion: issue.version,
      status: api.IssueStatus.Enum.IN_PROCESS,
      issueAnswerStatus: api.IssueAnswer.StatusEnum.DELETED,
    })
    onUpdateIssueHandler?.(issue, nextIssue)
  }

  const isIssueClosed = issue.status === api.IssueStatus.Enum.CLOSED

  return (
    <>
      <div>
        <FieldName>Описание</FieldName>

        <TextFieldMultilineWithPreview
          value={issue.description || ''}
          onSubmit={(value: string) => onUpdateIssue({ description: value })}
          viewMaxLength={1024}
          styleText={{
            fontWeight: 400,
            ...descriptionFieldStyles,
          }}
          disabled={issuePending || isIssueClosed}
          maxLength={1024}
          emptyMessage='Не задано'
          allowSubmitEmpty
          styleTextArea={descriptionFieldStyles}
          editIcon={smallPencil}
          editable={!!issue.allowedActions?.changeableFields?.description}
        />
      </div>
      {issueAnswer?.status === 'PENDING' && (
        <IssueOfficialReply
          officialReply={issueAnswer}
          onDelete={
            issue.assignees.some(
              ({ assigneeId }) => assigneeId === userInfo?.id,
            ) && //TODO Костыль, придумать как эту логику утащить на бэк
            issue.allowedActions?.changeStatus?.[
              api.IssueStatus.Enum.IN_PROCESS
            ] &&
            issue.status === api.IssueStatus.Enum.ON_REVIEW
              ? onDeleteOfficialReply
              : null
          }
          onAccept={
            issue.allowedActions?.changeStatus?.[api.IssueStatus.Enum.CLOSED]
              ? onAcceptOfficialReply
              : null
          }
          onReject={
            issue.allowedActions?.changeStatus?.[api.IssueStatus.Enum.CLOSED]
              ? onRejectOfficialReply
              : null
          }
          disabled={issuePending}
        />
      )}
      <div>
        <AttachmentTitlePanel>
          <FieldName>Приложения</FieldName>
          <AttachmentCount>{fileList?.length || 0}</AttachmentCount>
          {!!issue.allowedActions?.file?.add && (
            <Tooltip
              title={isMaxCountFiles ? maxCountErrorMessage : 'Добавить файл'}
              styleContent={{
                width: 'max-content',
              }}
              noMaxWidth
            >
              <AttachFile
                variant='default'
                disabled={issuePending || isMaxCountFiles || isIssueClosed}
                onChange={onUploadFile}
              />
            </Tooltip>
          )}
        </AttachmentTitlePanel>
        {slicedFileList.length !== 0 && (
          <div>
            <Gallery
              disabled={issuePending}
              fileList={slicedFileList}
              onDeleteFile={onDeleteFile}
              onDownloadFile={onDownloadFile}
              getImgSrc={file => getImageLink(file.id)}
              onOpenViewer={onOpenViewer}
              isDeletable={file =>
                !!issue.allowedActions?.file?.delete?.some(id => file.id === id)
              }
            />
            {Number(fileList?.length) > initialCountFiles && (
              <ShowAllWrapper>
                <ShowAll
                  hideButtonText='Скрыть все файлы'
                  showButtonText='Показать все файлы'
                  onSwitch={() => setIsHidden(prev => !prev)}
                  isHidden={isHidden}
                />
              </ShowAllWrapper>
            )}
          </div>
        )}
      </div>
      <div>
        <FieldName>Активность</FieldName>
        <Tabs
          onChangeTab={setTabIndex}
          activeTabIndex={tabIndex}
          headerStyles={{
            fontSize: '14px',
            height: '40px',
            gap: 19,
            marginTop: 'auto',
            marginBottom: '-1px',
            borderBottom: 'none',
          }}
          tabs={[
            {
              title: 'Комментарии',
              content: null,
            },
            {
              title: 'История',
              content: null,
            },
          ]}
        />

        {tabIndex === TabIndex.comment && (
          <Comments
            issue={issue}
            userInfo={userInfo}
            disabled={issue.status === api.IssueStatus.Enum.CLOSED}
            onUpdateIssueHandler={onUpdateIssueHandler}
          />
        )}
        {tabIndex === TabIndex.activity && (
          <ActivityWrapper>
            <EditIssuePopupHistoryChanges issue={issue} />
          </ActivityWrapper>
        )}
      </div>
    </>
  )
}
