import { ISSUE_FILTER_ID, useAssignees } from '@gmini/helpers'
import { useEffect } from 'react'
import qs from 'query-string'

import { useHistory } from 'react-router-dom'
import * as ismApi from '@gmini/ism-api-sdk'
import { parseIssueFilter } from '@gmini/helpers/src/filterService/issue'

import {
  assigneeAllUserList$,
  assigneeCompanyList$,
  assigneeRoleList$,
} from '../../assigneeGroupList'
import { filterService as issueFilterService } from '../../issueFilter.store'
import { TabIndex } from '../../../constants'

type UseSetSavedFilterProps = {
  selectedTab: number
}

export const useSetIssueSavedFilter = ({
  selectedTab,
}: UseSetSavedFilterProps) => {
  const history = useHistory()
  const getAssignees = useAssignees({
    assigneeRoleList$,
    assigneeUserList$: assigneeAllUserList$,
    assigneeCompanyList$,
  })

  const { applyFilters } = issueFilterService.appliedFilters.useUpdateFilter()
  const queries = qs.parse(window.location.search)
  const issueFilterId = Number(queries[ISSUE_FILTER_ID])
  const hasIssueFilterId =
    selectedTab === TabIndex.IssueList &&
    !isNaN(Number(queries[ISSUE_FILTER_ID]))
      ? Number(queries[ISSUE_FILTER_ID])
      : null

  useEffect(() => {
    if (!hasIssueFilterId) {
      return
    }
    ;(async () => {
      const currentIssueFilter =
        await issueFilterService.savedFilters.fetchSavedFilter({
          id: Number(issueFilterId),
        })

      const parsedIssueFilter = parseIssueFilter(currentIssueFilter)

      applyFilters({
        ...parsedIssueFilter,
        createdDateCode: parsedIssueFilter.createdDateCode
          ? ismApi.Filters.IssueDateCodeEnum[parsedIssueFilter.createdDateCode]
          : null,
        deadlineCode: parsedIssueFilter.deadlineCode
          ? ismApi.Filters.IssueLDeadlineCodeEnum[
              parsedIssueFilter.deadlineCode
            ]
          : null,
        updatedDateCode: parsedIssueFilter.updatedDateCode
          ? ismApi.Filters.IssueDateCodeEnum[parsedIssueFilter.updatedDateCode]
          : null,
        assignees: getAssignees(parsedIssueFilter.assignees || []),
        attributeValueIds: parsedIssueFilter.attributeValueIds.map(String),
      })

      const prev = qs.parse(window.location.search)
      const search = qs.stringify(
        {
          ...prev,
          [ISSUE_FILTER_ID]: null,
        },
        { skipNull: true },
      )

      history.push({ search })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasIssueFilterId])

  return { hasIssueFilterId }
}
