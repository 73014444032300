import * as React from 'react'
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  CssBaseline,
} from '@mui/material'
import { Helmet } from 'react-helmet'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { root } from 'effector-root'
import { createInspector } from 'effector-logger/inspector'
import { attachLogger } from 'effector-logger/attach'

import { ReactKeycloakProvider } from '@react-keycloak/web'
import { AuthClientTokens } from '@react-keycloak/core/lib/types'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import {
  DefaultSnackbarProvider,
  muiTheme,
  OfflineModal,
  PageTitleOptions,
} from '@gmini/ui-kit'
import MultiBackend from 'react-dnd-multi-backend'
import { DndProvider } from 'react-dnd'

import { Page404 } from '@gmini/ui-kit/lib/Page404'

import { MetrikaProvider } from '@gmini/common/lib/metrika/provider'

import { AuthContent } from '@gmini/common/lib/keycloakAuth/AuthContent'
import { saveAuthData } from '@gmini/common/lib/keycloakAuth/auth'

import { ApiCallService } from '@gmini/api-call-service'

import * as api from '@gmini/ism-api-sdk'

import { CustomHTML5toTouch } from '../dnd'

import * as config from '../config'

import '../services/issuesService'
import { notificationService } from '../services/omniNotificationsService'
import {
  IssueManagerPage,
  EditIssuePage,
  CreateIssuePage,
} from '../components/pages'

import './styles.css'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

if (config.logEffector) {
  attachLogger(root)
  createInspector()
}

export const App = (): JSX.Element => {
  const onTokens = React.useCallback(
    ({ idToken, refreshToken, token }: AuthClientTokens) => {
      if (!token || !refreshToken || !idToken) {
        // Если приходят пустые токены, то значит юзер разлогинился или залогинился под другим юзернеймом на другой вкладке
        // и кейклок сам сделает редирект на страницу авторизации что бы обновить куки
        return
      }

      saveAuthData({
        accessToken: token,
        refreshToken,
        idToken,
      })
    },
    [],
  )

  // redirect /issue-manager/**** => /****
  if (window.location.pathname.indexOf('/issue-manager') === 0) {
    window.location.replace(
      `${window.location.origin}${window.location.pathname.replace(
        '/issue-manager',
        '',
      )}`,
    )
  }

  return (
    <ReactKeycloakProvider
      authClient={config.keycloakClient}
      onTokens={onTokens}
      initOptions={{
        onLoad: 'login-required',
        checkLoginIframe: false,
      }}
    >
      <MetrikaProvider>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={muiTheme}>
                <DefaultSnackbarProvider>
                  <PageTitleOptions siteName='Менеджер замечаний' />
                  <CssBaseline />
                  <Helmet>
                    <link
                      rel='stylesheet'
                      href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'
                    />
                  </Helmet>
                  <DndProvider
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    backend={MultiBackend as any}
                    options={CustomHTML5toTouch}
                  >
                    <OfflineModal />

                    <AuthContent appName='Менеджер замечаний'>
                      <Switch>
                        <Route path='/' exact component={IssueManagerPage} />
                        <Route
                          path='/issue/create'
                          exact
                          component={CreateIssuePage}
                        />
                        <Route
                          path='/issue/edit/:issueId'
                          exact
                          component={EditIssuePage}
                        />
                        <Route component={Page404} />
                      </Switch>
                    </AuthContent>
                  </DndProvider>
                </DefaultSnackbarProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </LocalizationProvider>
        </BrowserRouter>
      </MetrikaProvider>
    </ReactKeycloakProvider>
  )
}

App.displayName = 'App'

ApiCallService.fail.watch(({ name, params, error }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (error.status === 'fail' || (error as any).name === 'AbortError') {
    return
  }

  api.logApiError(error)
})

notificationService.validationError.watch(params => {
  params.errors.forEach(error => {
    console.error(`WebSocket error:\n ${JSON.stringify(error, null, 2)}`)
  })
})

notificationService.connect({
  url: config.wsUrlV2,
})
