import {
  Container,
  Image,
  File,
  FileWrapper,
  Download,
  Eye,
  Trash,
  ActionPanel,
  ActionButton,
} from './GalleryItem.styled'
import { GalleryItemFile } from './GalleryItem.types'

type GalleryItemProps<F> = {
  file: F
  onDelete?: (file: F) => void
  onClickView?: (file: F) => void
  onDownload?: (file: F) => void
  getImgSrc?: (file: F) => void
  disabledDelete?: boolean
  isDeletable?: (file: F) => boolean
}

export const GalleryItem = <F extends GalleryItemFile>({
  file,
  onDelete,
  onClickView,
  onDownload,
  getImgSrc,
  disabledDelete,
  isDeletable,
}: GalleryItemProps<F>) => {
  const imgSrc = getImgSrc?.(file)

  return (
    <Container>
      {imgSrc ? (
        <>
          <Image src={imgSrc} />
          <Eye onClick={() => onClickView?.(file)} />
        </>
      ) : (
        <FileWrapper>
          <File />
          {file.name.split('.').pop()}
        </FileWrapper>
      )}
      <ActionPanel>
        {onDownload && (
          <ActionButton onClick={() => onDownload(file)}>
            <Download />
          </ActionButton>
        )}
        {onDelete && isDeletable?.(file) && (
          <ActionButton
            disabled={disabledDelete}
            onClick={() => onDelete(file)}
          >
            <Trash />
          </ActionButton>
        )}
      </ActionPanel>
    </Container>
  )
}
