import * as ismApi from '@gmini/ism-api-sdk'

import { allowedFiltersService } from '@gmini/helpers'

import { attributesService } from '../../attribute.store'

export const {
  allowedFilters$,
  fetchAllowedFilters,
  fetchAllowedFiltersPending$,
} = allowedFiltersService({
  fetchAllowedFiltersApi: ismApi.GTechIssueTemplate.fetchAllowedFilters,
})

fetchAllowedFilters.doneData.watch(data => {
  attributesService.fetchAttributeValues({
    valueIds: data.attributeValueIds,
  })
})
