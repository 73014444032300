import { ISSUE_ID } from '@gmini/helpers'
import qs from 'query-string'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { limit } from './constants'

export const useIssueNavigation = ({
  startIndex,
  list,
  loadMore,
}: {
  startIndex: number | null
  list: number[]
  loadMore?: (offset: number) => void
}) => {
  const history = useHistory()
  const [offset, setOffset] = useState(limit)
  const lastIndex = list.length
  const firstIndex = 0
  let nextDisabled = false
  let prevDisabled = false
  const beforeLastIndex = list.length - 2
  const currentIndex = list.findIndex(el => el === startIndex)

  currentIndex === firstIndex ? (prevDisabled = true) : (prevDisabled = false)

  currentIndex === lastIndex - 1
    ? (nextDisabled = true)
    : (nextDisabled = false)

  const prevSearchUrl = qs.parse(window.location.search)

  const onNextIssue = useCallback(() => {
    const nextIndex = list[currentIndex + 1]
    const search = qs.stringify({ ...prevSearchUrl, [ISSUE_ID]: nextIndex })
    history.push({ search })
  }, [currentIndex, history, list, prevSearchUrl])

  const onPrevIssue = useCallback(() => {
    const prevIndex = list[currentIndex - 1]
    const search = qs.stringify({ ...prevSearchUrl, [ISSUE_ID]: prevIndex })
    history.push({ search })
  }, [currentIndex, history, list, prevSearchUrl])

  useEffect(() => {
    if (currentIndex === beforeLastIndex) {
      loadMore?.(offset)
      setOffset(prev => prev + limit)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beforeLastIndex, currentIndex])
  return { onNextIssue, onPrevIssue, nextDisabled, prevDisabled }
}
