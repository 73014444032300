import { Popup } from '@gmini/ui-kit/lib/Popup'
import { memo, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import {
  ISSUE_ID,
  getIssuesManagerLink,
  omniSubscriptionService,
} from '@gmini/helpers'
import { EditIssueIFrame } from '@gmini/ui-common'
import { RoundedCross } from '@gmini/ui-kit'

import qs from 'query-string'

import { dissoc } from 'ramda'

import { envLinks, localDevUrl } from '../../../config'
import { PreparedIssue, updateIssue } from '../../issue.store'

import { DEVELOPMENT_MODE, IFRAME_CONTAINER_ID } from '../../../constants'

import { CurrentIssue } from '../../pages/EditIssuePage/types'

import {
  CloseButton,
  IFrameContainer,
  RightSideHeader,
} from './EditIssuePopup.styled'

type EditIssuePopupProps = {
  issue: PreparedIssue | null
  selectedIssueId: number | null
  onNextIssue?: (currentIssue: CurrentIssue) => void
  onPrevIssue?: (currentIssue: CurrentIssue) => void
  nextDisabled?: boolean
  prevDisabled?: boolean
}

export const EditIssuePopup = memo(
  ({
    issue,
    selectedIssueId,
    onNextIssue,
    onPrevIssue,
    nextDisabled,
    prevDisabled,
  }: EditIssuePopupProps) => {
    const history = useHistory<History>()

    const issuesManagerLink = useMemo(
      () => getIssuesManagerLink(envLinks, localDevUrl, DEVELOPMENT_MODE) || '',
      [],
    )

    const onClose = () => {
      const prev = dissoc(ISSUE_ID, qs.parse(window.location.search))
      const search = qs.stringify({ ...prev })
      history.push({ search })
    }

    const onUpdateIssue = useCallback(
      (_prevIssue, currentIssue: PreparedIssue) => {
        updateIssue(currentIssue)
        omniSubscriptionService.updateSubscriptionsMap({
          [currentIssue.id]: currentIssue.subscriptionPublicId || '',
        })
      },
      [],
    )

    if (selectedIssueId === null) {
      return null
    }
    return (
      <Popup
        id={IFRAME_CONTAINER_ID}
        open={!!selectedIssueId}
        onClose={onClose}
        maxWidth='1002px'
      >
        <IFrameContainer>
          <RightSideHeader>
            <CloseButton
              onClick={onClose}
              type='square'
              data-test-id='closeIssue'
            >
              <RoundedCross />
            </CloseButton>
          </RightSideHeader>
          <EditIssueIFrame
            issueId={issue?.id || selectedIssueId}
            baseUrl={issuesManagerLink}
            additionalContentWrapperStyle={{ paddingTop: '80px' }}
            onUpdateIssue={onUpdateIssue}
            switchIssueProps={{
              onNextIssue,
              onPrevIssue,
              nextDisabled,
              prevDisabled,
            }}
          />
        </IFrameContainer>
      </Popup>
    )
  },
)
