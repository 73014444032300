import { ApiCallService } from '@gmini/api-call-service'
import { combine, createEvent, createStore } from 'effector'
import { clone } from 'ramda'

import * as api from '@gmini/ism-api-sdk'

import { ZERO_SEARCH } from '../constants'

type IdsBySearchValue = Record<string | symbol, number[] | undefined>

export function createIssueTemplateService({
  fetchIssueTemplateListApi,
}: {
  fetchIssueTemplateListApi: ApiCallService<
    api.GTechIssueTemplate.FetchListParams,
    api.GTechIssueTemplate.Data
  >
}) {
  const resetTemplateList = createEvent()
  const fetchIssueTemplateList = fetchIssueTemplateListApi.createContext()
  const fetchIssueTemplateListPending$ = fetchIssueTemplateList.pending$

  type ById = {
    [id: string]: api.GTechIssueTemplate
  }

  const byId$ = createStore<ById>({}).on(
    fetchIssueTemplateList.doneData,
    (state, result) => {
      const next = clone(state)

      result.list.forEach((template, idx) => (next[template.id] = template))

      return next
    },
  )

  const ids$ = createStore<IdsBySearchValue>({})
    .on(fetchIssueTemplateList.done, (state, { params, result }) => {
      const next = { ...state }
      const search = params.filter || ZERO_SEARCH

      next[search] = [
        ...new Set([
          ...(state[search] || []),
          ...result.list.map(({ id }) => id),
        ]),
      ]

      return next
    })
    .on(resetTemplateList, () => ({}))

  const totalTemplates$ = createStore<number | null>(null).on(
    fetchIssueTemplateList.done,
    (state, { result }) => result.total,
  )

  return {
    templateList$: combine({ byId$, ids$, totalTemplates$ }),
    resetTemplateList,
    fetchIssueTemplateList,
    fetchIssueTemplateListPending$,
    byId$,
    ids$,
  }
}
