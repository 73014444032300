import { Popup } from '@gmini/ui-kit/lib/Popup'
import { PROJECT_URN, getIssuesManagerLink, queryParser } from '@gmini/helpers'
import { CreateIssueIFrame } from '@gmini/ui-common'
import { useMemo } from 'react'
import * as api from '@gmini/ism-api-sdk'
import { RoundedCross } from '@gmini/ui-kit'

import { envLinks, localDevUrl } from '../../../config'
import { DEVELOPMENT_MODE } from '../../../constants'

import {
  CloseButton,
  IFrameContainer,
  RightSideHeader,
} from './CrateIssuePopup.styled'

type CreateIssuePopupProps = {
  open: boolean
  onClose: () => void
  onCreateIssue: (issue: api.GTechIssue.IssuePopulated) => void
}

export const CreateIssuePopup = ({
  open,
  onClose,
  onCreateIssue,
}: CreateIssuePopupProps) => {
  const projectUrn = queryParser({ key: PROJECT_URN }) as string
  const issuesManagerLink = useMemo(
    () => getIssuesManagerLink(envLinks, localDevUrl, DEVELOPMENT_MODE) || '',
    [],
  )

  const onCreateIssueHandler = (issue: api.GTechIssue.IssuePopulated) => {
    onCreateIssue(issue)
    onClose()
  }

  if (!open) {
    return null
  }
  return (
    <Popup open={open} onClose={onClose} maxWidth='600px'>
      <IFrameContainer>
        <RightSideHeader>
          <CloseButton type='square'>
            <RoundedCross onClick={onClose} />
          </CloseButton>
        </RightSideHeader>
        <CreateIssueIFrame
          baseUrl={issuesManagerLink}
          projectUrn={projectUrn}
          onCreateIssue={onCreateIssueHandler}
          onCloseIFrame={onClose}
        />
      </IFrameContainer>
    </Popup>
  )
}
