import { combine } from 'effector'

import {
  updateGTechIssuePending$,
  changeStatusPending$,
} from '../../issue.store'
import {
  deleteFilePending$,
  addFilePending$,
  startUploadFilePending$,
} from '../../file.store'
import {
  createIssueCommentPending$,
  removeIssueCommentPending$,
} from '../Comments/model'
import { fetchStatusListPending$ } from '../../issueStatus.store'

export const actionIssuePending$ = combine(
  [
    updateGTechIssuePending$,
    addFilePending$,
    deleteFilePending$,
    fetchStatusListPending$,
    changeStatusPending$,
    createIssueCommentPending$,
    removeIssueCommentPending$,
    startUploadFilePending$,
  ],
  pendings => pendings.some(Boolean),
)
