import {
  passesFilterAssigneeMultiple,
  passesFilterDate,
  passesFilterUser,
} from '@gmini/components'
import { filterDateEnrichment } from '@gmini/helpers'
import * as api from '@gmini/ism-api-sdk'

import { IssueTemplateListFilters } from './IssueTemplateList.types'

export const matchIssueTemplateToFilters = (
  issueTemplate: api.GTechIssueTemplate,
  filters: IssueTemplateListFilters,
) => {
  const isPassAssigneeFilter = passesFilterAssigneeMultiple(
    filters.assignees,
    issueTemplate.assignees,
  )

  const isPassAuthorFilter = passesFilterUser(
    filters.authorIds || [],
    issueTemplate.authorId,
  )

  const currentDate = new Date()

  const { enrichedUpdatedDateRange, enrichedCreatedDateRange } =
    filterDateEnrichment(currentDate, filters)

  const isPassUpdatedDateFilter = passesFilterDate(
    enrichedUpdatedDateRange,
    issueTemplate.updatedAt,
  )

  const isPassCreatedDateFilter = passesFilterDate(
    enrichedCreatedDateRange,
    issueTemplate.createdAt,
  )

  const isPassSearchFilter = issueTemplate.name
    .toLowerCase()
    .includes(filters.filter.toLowerCase())

  return (
    isPassAssigneeFilter &&
    isPassAuthorFilter &&
    isPassUpdatedDateFilter &&
    isPassCreatedDateFilter &&
    isPassSearchFilter
  )
}
