/* eslint-disable react/display-name */
import { useCallback, useState } from 'react'
import { useStore } from 'effector-react'
import {
  SubscribeIcon,
  UnsubscribeIcon,
  snackbarDataTestId,
} from '@gmini/components'
import { Snackbar, Tooltip } from '@gmini/ui-kit'
import { ClickAwayListener } from '@mui/material'
import * as api from '@gmini/ism-api-sdk'

import { omniSubscriptionService } from '@gmini/helpers'

import { userInfo$ } from '@gmini/common'

import { useSnackbar } from 'notistack'

import { getCreateOmniSubscriptionParams } from '../../organisms/IssueList/converter'

import {
  //TODO: закомменчено до времен, пока нельзя будет видеть всех подписавшихся на замечание
  // Divider,
  // IssueSubscribersTitle,
  IssueSubscriptionsPopup,
  IssueSubscriptionsPopupListItem,
  IssueSubscriptionsWrapper,
  StyledEye,
  // StyledAvatar,
  StyledIconButton,
} from './EditIssue.styled'
import { CurrentIssue } from './types'

type IssueSubscriptionsMenuProps = {
  disabled?: boolean
  issue: CurrentIssue
  projectUrn: string
  onUpdateIssueHandler?: (
    prevIssue: api.GTechIssue.IssuePopulated,
    currentIssue: CurrentIssue,
  ) => void
}

export const IssueSubscriptionsMenu = ({
  disabled,
  issue,
  projectUrn,
  onUpdateIssueHandler,
}: IssueSubscriptionsMenuProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const userInfo = useStore(userInfo$)
  const [isIssueSubscriptionsPopupOpen, setIsIssueSubscriptionsPopupOpen] =
    useState(false)

  const subscriptionPending = useStore(
    omniSubscriptionService.subscriptionsPending$,
  )

  const { subscriptionPublicId } = issue

  const isSubscriptionUnavailable =
    userInfo?.id === issue.author ||
    issue.assignees.some(assignee => assignee.assigneeId === userInfo?.id)

  const handleSubscribe = useCallback(async () => {
    const res = await (subscriptionPublicId
      ? omniSubscriptionService.removeSubscription({
          id: subscriptionPublicId,
        })
      : omniSubscriptionService.createSubscription(
          getCreateOmniSubscriptionParams({
            id: `${issue.id}`,
            projectUrn,
          }),
        ))
    enqueueSnackbar('', {
      content: key => (
        <Snackbar
          data-test-id={snackbarDataTestId}
          id={key}
          message={
            subscriptionPublicId
              ? `Замечание убрано из списка отслеживаемых`
              : `Замечание добавлено в список отслеживаемых`
          }
          onClose={() => closeSnackbar(key)}
        />
      ),
      variant: 'info',
    })
    onUpdateIssueHandler?.(issue, {
      ...issue,
      subscriptionPublicId: res?.publicId || '',
    })
  }, [
    subscriptionPublicId,
    issue,
    onUpdateIssueHandler,
    projectUrn,
    enqueueSnackbar,
    closeSnackbar,
  ])

  if (isSubscriptionUnavailable) {
    return null
  }

  return (
    <IssueSubscriptionsWrapper disabled={disabled || subscriptionPending}>
      <Tooltip title='Параметры отслеживания'>
        <StyledIconButton
          active={isIssueSubscriptionsPopupOpen}
          onClick={() => setIsIssueSubscriptionsPopupOpen(true)}
          data-test-id='issueSubscriptionsMenuOpenBtn'
        >
          <StyledEye active={!!subscriptionPublicId} />
          {/* TODO: закомменчено до времен, пока нельзя будет видеть всех подписавшихся на замечание */}
          {/* <span>{subscribers.length}</span> */}
        </StyledIconButton>
      </Tooltip>
      {isIssueSubscriptionsPopupOpen && (
        <ClickAwayListener
          onClickAway={() => setIsIssueSubscriptionsPopupOpen(false)}
        >
          <IssueSubscriptionsPopup>
            <Tooltip
              title={
                isSubscriptionUnavailable
                  ? 'Нельзя подписаться/отписаться в замечании, где вы являетесь автором или ответственным'
                  : ''
              }
            >
              <IssueSubscriptionsPopupListItem
                data-test-id='issueSubscriptionsMenuToggleSubscriptionBtn'
                disabled={isSubscriptionUnavailable}
                onClick={handleSubscribe}
              >
                {subscriptionPublicId ? (
                  <>
                    <UnsubscribeIcon />
                    <span>Не отслеживать замечание</span>
                  </>
                ) : (
                  <>
                    <SubscribeIcon />
                    <span>Отслеживать замечание</span>
                  </>
                )}
              </IssueSubscriptionsPopupListItem>
            </Tooltip>
            {/* TODO: закомменчено до времен, пока нельзя будет видеть всех подписавшихся на замечание */}
            {/* {!!subscribers.length && (
              <>
                <Divider />
                <IssueSubscribersTitle>
                  Это замечание отслеживают
                </IssueSubscribersTitle>
                {subscribers.map(sub => {
                  const user = allUserMap[sub]
                  return (
                    <IssueSubscriptionsPopupListItem key={sub}>
                      <StyledAvatar />
                      <span>
                        {user.firstName && user.lastName
                          ? `${user.lastName} ${user.firstName}`
                          : user.email}
                      </span>
                    </IssueSubscriptionsPopupListItem>
                  )
                })}
              </>
            )} */}
          </IssueSubscriptionsPopup>
        </ClickAwayListener>
      )}
    </IssueSubscriptionsWrapper>
  )
}
